<template>
  <div class="inteBox">
    <intNavheader></intNavheader>
    <div class="titleTopLine"></div>
    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <waitNumber :waitDataTotalNumber='waitDataTotal'></waitNumber>
        <div class="titleTopCenterLeftBox" @click="gonewDoctor">新增患者</div>
      </div>
      <div class="titleTopCenterCenter" v-show="!addtoNew" style="padding-bottom:20px;overflow-y: auto;">
        <div class="titleTopCenterCenterTop">
          <el-page-header @back="goBack" content="患者详情"></el-page-header>
          <div class="noflex-l-s" style="margin-top: 40px">
            <div style="width: 30%;margin-right: 30px;">
              <div class="titleTopright1">{{ refeDetail?.name || '-' }}</div>
              <div class="titleTopRight">
                <p>性别：<span>{{ refeDetail?.sex ? '男' : '女' || '-' }}</span></p>
                <p>年龄：<span>{{ refeDetail?.age || '-' }}</span></p>
                <p><i class="el-icon-phone greenColor"></i><span>{{ refeDetail?.phone || '-' }}</span></p>
              </div>
            </div>
            <div style="width: 50%">
              <div class="flex-l">
                <div style="width: 50%;">
                  <span>身份证号：</span><span>{{ refeDetail?.identity || '-' }}</span>
                </div>
                <div style="width: 50%;">
                  <span>初步诊断：</span><span>{{ refeDetail?.diagnosis || '-' }}</span>
                </div>
              </div>
              <div class="flex-l" style="padding: 30px 0">
                <div style="width: 50%;">
                  <span>联系地址：</span><span>{{ refeDetail?.areaFullName || '暂未填写' }}</span>
                </div>
                <div style="width: 50%;">
                  <span>病情程度：</span><span>{{ refeDetail?.degree || '暂未填写' }}</span>
                </div>
              </div>
              <div class="flex-l">
                <div style="width: 50%;">
                  <span>转诊原因：</span><span>{{ refeDetail?.reason || '暂未填写' }}</span>&nbsp;&nbsp;<span>{{ refeDetail.introduction ? refeDetail.introduction : refeDetail.reasonDetails}}</span>
                </div>
                
              </div>
            </div>

          </div>
        </div>
        <p class="titleTopCenterCenterTopLine" style="margin-top: 20px;font-size: 18px;">详情：</p>
        <div class="noflex-l-s">
          <div class="titleTopLeftTable" style="border: none">
            <div class="flex-l" style="margin-top: 10px" v-if="refeDetail?.state == 11">
              <div class="titleTopLeftTableTag"></div>
              <div class="contentTitle">患者处理结果</div>
            </div>
            <div style="margin-left: 15px; margin-top: 5px" v-if="refeDetail?.state == 11">
              <div></div>
              <div>
                <span>{{ refeDetail?.results || '-' }}</span>
              </div>
            </div>
            <div class="flex-l" style="margin-top: 10px">
              <div class="titleTopLeftTableTag"></div>
              <div class="contentTitle">初步诊断</div>
            </div>
            <div style="margin-left: 15px; margin-top: 5px">
              <div></div>
              <div>
                <span>{{ refeDetail?.diagnosis || '-' }}</span>
              </div>
            </div>
            <!-- <div class="flex-l" style="margin-top: 10px">
              <div class="titleTopLeftTableTag"></div>
              <div class="contentTitle">鉴别分析</div>
            </div>
            <div style="margin-left: 15px; margin-top: 5px">
              <div></div>
              <div>
                <span>{{ refeDetail?.diagnosis || '-' }}</span>
              </div>
            </div> -->
            <div class="flex-l" style="margin-top: 10px">
              <div class="titleTopLeftTableTag"></div>
              <div class="contentTitle">病史</div>
            </div>
            <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
              <div class="titleTopLeftTableTag1"></div>
              <div style="color: #999999">家族病史</div>
              <div style="margin-left: 15px">
                <span>{{ patientDiagnose?.family || '-' }}</span>
              </div>
            </div>
            <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
              <div class="titleTopLeftTableTag1"></div>
              <div style="color: #999999">过敏史</div>
              <div style="margin-left: 15px">
                <span>{{ patientDiagnose?.irritability || '-' }}</span>
              </div>
            </div>
            <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
              <div class="titleTopLeftTableTag1"></div>
              <div style="color: #999999">既往史</div>
              <div style="margin-left: 15px">
                <span>{{ patientDiagnose?.past || '-' }}</span>
              </div>
            </div>
            <div class="flex-l" style="margin-top: 10px">
              <div class="titleTopLeftTableTag"></div>
              <div class="contentTitle">主诉</div>
            </div>
            <div style="margin-left: 15px; margin-top: 5px">
              <div></div>
              <div>{{ patientDiagnose?.main || '-' }}</div>
            </div>
            <div class="flex-l" style="margin-top: 10px">
              <div class="titleTopLeftTableTag"></div>
              <div class="contentTitle">体格检查</div>
            </div>
            <div style="margin-left: 15px; margin-top: 5px">
              <div class="flex-l">
                <div style="width: 160px;">
                  <span>体温：</span><span>{{ patientDiagnose?.temperature || '-' }} ℃</span>
                </div>
                <div style="width: 160px;">
                  <span>体重：</span><span>{{ patientDiagnose?.weight || '-' }}kg</span>
                </div>
                <div style="width: 160px;">
                  <span>血压：</span><span>{{ patientDiagnose?.heightTension || '-' }}</span>/<span style="color: #01C2AC">{{
                    patientDiagnose?.heightTension || '-' }}</span>mmHg
                </div>
              </div>
              <div class="flex-l">
                <div style="width: 160px;">
                  <span>身高：</span><span>{{ patientDiagnose?.height || '-' }}CM</span>
                </div>
                <div style="width: 160px;">
                  <span>呼吸：</span><span>{{ patientDiagnose?.breathe || '-' }}次/分</span>
                </div>
                <div style="width: 160px;">
                  <span>脉搏：</span><span>{{ patientDiagnose?.pulse || '-' }}次/分</span>
                </div>
              </div>
              <div class="flex-l">
                <div style="width: 160px;">
                  <span>其他：</span><span>{{ patientDiagnose?.otherCheck || '-' }}</span>
                </div>
              </div>
            </div>
            <div class="flex-l" style="margin-top: 10px">
              <div class="titleTopLeftTableTag"></div>
              <div class="contentTitle">症状图片</div>
            </div>
            <div style="margin-left: 15px; margin-top: 5px; flex-wrap: wrap" v-if="patientDetailAll?.diagnoseImgList"
              class="flex-l">
              <div v-for="(item, index) in patientDetailAll?.diagnoseImgList" :key="index">
                <a :href="Services.Authorization + item.filePath" target="_blank" title="查看最大化图片">
                  <img :src="Services.Authorization + item.filePath" class="avatar" />
                </a>
              </div>
            </div>
            <div class="flex-l" style="margin-top: 10px">
              <div class="titleTopLeftTableTag"></div>
              <div class="contentTitle">处方图片</div>
            </div>
            <div style="margin-left: 15px; margin-top: 5px; flex-wrap: wrap" v-if="patientDetailAll?.prescriptionImgList"
              class="flex-l">
              <div v-for="(item, index) in patientDetailAll?.prescriptionImgList" :key="index">
                <a :href="Services.Authorization + item.filePath" target="_blank" title="查看最大化图片">
                  <img :src="Services.Authorization + item.filePath" class="avatar" />
                </a>
              </div>
            </div>
          </div>
          <div class="titleTopLeftTable" style="border: none">
            <div class="flex-l" style="margin-top: 10px">
              <div class="titleTopLeftTableTag"></div>
              <div class="contentTitle">时间轴</div>
            </div>
            <el-timeline v-if="timelines?.length">
              <el-timeline-item v-for="(activity, index) in timelines" :key="index" placement="top"
                :timestamp="activity.receptionTime | timefilters">
                <el-card>
                  <h4>{{ activity.remark }}</h4>
                  <p>{{ activity.handHospitalName }}&nbsp;&nbsp;{{ activity.handDermantName }}</p>
                  <p>医生：{{ activity.handler }}</p>
                </el-card>
              </el-timeline-item>
            </el-timeline>
            <div class="no-data" v-if="!timelines?.length">
              <img src="../../assets/img/no-data1.png" />
              <p>暂无时间轴信息</p>
            </div>
          </div>
        </div>
      </div>
      <div class="titleTopCenterCenter" v-show="addtoNew" style="padding-bottom:20px;overflow-y: auto;">
        <div class="titleTopCenterCenterTop">
          <el-page-header @back="goBack" content="患者详情"></el-page-header>
          <div class="addreferral">
            <el-form ref="AddReferralform" :rules="rules" :model="AddReferralform" label-width="100px">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="姓名" prop="name">
                    <el-input v-model="AddReferralform.name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="性别">
                    <el-radio v-model="AddReferralform.sex" label="1">男</el-radio>
                    <el-radio v-model="AddReferralform.sex" label="0">女</el-radio>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="年龄" prop="age">
                    <el-input v-model="AddReferralform.age"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="联系电话" prop="phone">
                    <el-input v-model="AddReferralform.phone"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="病情程度">
                    <el-select v-model="AddReferralform.degree" placeholder="请选择病情程度" style="width: 100%">
                      <el-option label="轻度" value="1"></el-option>
                      <el-option label="中度" value="2"></el-option>
                      <el-option label="重度" value="3"></el-option>
                      <el-option label="严重" value="4"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="身份证号" prop="identity">
                    <el-input v-model="AddReferralform.identity"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="初步诊断" prop="diagnosis">
                    <el-input v-model="AddReferralform.diagnosis"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="备注">
                    <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="AddReferralform.introduction">
                    </el-input>
                    <!-- <quill-editor v-model="AddReferralform.introduction" ref="myQuillEditor" :content="AddReferralform.introduction" :options="editorOption" @change="onEditorChange($event)" /> -->
                  </el-form-item>
                </el-col>
              </el-row>
              <!--<tinymce-editor ref="editor" v-model="value"></tinymce-editor>-->
              <el-form-item>
                <el-button type="primary" @click="SaveReferralform('AddReferralform')">保存</el-button>
                <el-button @click="cancelDialog('AddReferralform')">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <div class="titleTopLine"></div>

  </div>
</template>
<script>
import intNavheader from "../../components/intNavheader";
import waitNumber from "../../components/waitNumber";
import { Referral } from "../../components/Referral/Referral";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
export default {
  components: {
    intNavheader,
    waitNumber
  },
  //注册局部过滤器
  filters: {
    timefilters(val) {
      if (val == null || val == "") {
        return "暂无时间";
      } else {
        let d = new Date(val); //val 为表格内取到的后台时间
        let month = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
        let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
        let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
        let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
        let sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
        let times = d.getFullYear() + "-" + month + "-" + day + " " + hours + ":" + min + ":" + sec;
        return times;
      }
    },
  },
  data() {
    var patient = new patientMain(this.TokenClient, this.Services.Authorization);
    var drugpatient = new patientMain(this.TokenClient, this.Services.Drug);
    var referral = new Referral(this.TokenClient, this.Services.Referral);
    return {
      Referral: referral,
      patientDomain: patient,
      drugpatientDomain: drugpatient,
      userInfo: this.$store.state.localData.userInfo,
      eldialogVisible: false,
      patientDetail: {},
      addtoNew: false,
      num: "",
      patientid: 0,
      waitDataTotal: 0,
      visitData: [],
      refeDetail: {},
      timelines: [],
      referralId: null,
      AddReferralform: {
        id: 0,
        name: "",
        sex: "1",
        age: "",
        phone: "",
        degree: "",
        identity: "",
        diagnosis: "",
        introduction: "",
      },
      patientDiagnose: {},
      patientDetailAll:{},
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        age: [
          {
            pattern: /^(\d|[1-9]\d|1[0-1]\d|120)$/,
            message: "请输入不大于120的整数",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
            message: "请输入合法手机号/电话号",
            trigger: "blur",
          },
        ],
        identity: [
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确格式的身份证号码",
            trigger: "blur",
          },
        ],
        degree: [{ required: true, message: "请选择病情程度", trigger: "blur" }],
        diagnosis: [{ required: true, message: "请输入初步诊断", trigger: "blur" }],
        reason: [{ required: true, message: "请选择转诊原因", trigger: "blur" }],
        hospitalId: [{ required: true, message: "请选择医院", trigger: "blur" }],
        departmentId: [{ required: true, message: "请选择部门", trigger: "blur" }],
        doctorId: [{ required: true, message: "请选择医生", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.referralId = this.$route.query.referralId;
    let str = this.$route.query.type;
    if(str == 'referral'){
      this.getDetial();
    }else{
      this.getReceptionDetial()
    }
    this.gettWaitData()
    this.getUserMessage();

  },
  beforeDestory() {
    this.addtoNew = false
  },
  methods: {
    //请求的数据方法
    getDetial() {
      const _this = this;
      _this.Referral.refeDetail(
        _this.referralId,
        function (data) {
          let newObj = {};
          newObj = data.data;
          if (data.data.degree == '1') {
            newObj.degree = '轻度'
          } else if (data.data.degree == '2') {
            newObj.degree = '中度'
          } else if (data.data.degree == '3') {
            newObj.degree = '重度'
          } else if (data.data.degree == '4') {
            newObj.degree = '严重'
          }
          _this.refeDetail = newObj;
        },
        function (error) {
          console.log(error);
        }
      );
      _this.Referral.TimeLine(
        _this.referralId,
        function (data) {
          _this.timelines = data.data;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    //接诊数据
    getReceptionDetial() {
      const _this = this;
      _this.Referral.ReceptionDetail(
        _this.referralId,
        function (data) {
          let newObj = {};
          newObj = data.data;
          if (data.data.degree == '1') {
            newObj.degree = '轻度'
          } else if (data.data.degree == '2') {
            newObj.degree = '中度'
          } else if (data.data.degree == '3') {
            newObj.degree = '重度'
          } else if (data.data.degree == '4') {
            newObj.degree = '严重'
          }
          _this.refeDetail = newObj;
        },
        function (error) {
          console.log(error);
        }
      );
      _this.Referral.TimeLine(
        _this.referralId,
        function (data) {
          _this.timelines = data.data;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    
    //得到用户血压等信息
    getUserMessage(){
      if(this.$route.query.patientId){
        this.goDoctor();
      }
      
    },
    goDoctor() {
      var _this = this;
      this.patientDomain.GetPatientDetail(
        this.$route.query.patientId,
        function (data) {
          _this.patientDiagnose = data.data.patientDiagnose[0];
          _this.getPicture(data.data.patientDiagnose[0].id)
        },
        function (error) {
          console.log(error);
        }
      );
    },
    //获取图片
    getPicture(str){
      var _this = this;
      this.patientDomain.GetPatientDiagnoseDetail(
            str,
            function (data) {
          _this.patientDetailAll = data.data;
        },
        function (error) {
          console.log(error);
        }
      )
    },
    
    gettWaitData() {
      var _this = this;
      _this.waitDataTotal = 0;
      _this.drugpatientDomain.getMZIndex(
        function (data) {
          _this.waitDataTotal = data.data.waitAuditCount;
        },
        function (error) {
          _this.waitDataTotal = 0;
        }
      );
    },
    //返回
    goBack() {
      this.$router.push({
        name: "referralAndmedical",
      });
    },
    //新增患者
    gonewDoctor() {
      this.addtoNew = !this.addtoNew;
    },
    //新增、编辑保存事件
    SaveReferralform(formName) {
      var _this = this;
      var item = _this.AddReferralform;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.Referral.AddReferral(
            item.name,
            parseInt(item.sex),
            item.age,
            item.phone,
            item.identity,
            item.degree,
            item.diagnosis,
            item.introduction,
            0,
            '',
            function (data) {
              _this.addtoNew = false;
              _this.$message({
                type: "success",
                message: "新增成功!",
              });
            },
            function (err) {
              console.log(err);
            }
          );
        }
      });
    },
  },
};
</script>
<style scoped>
.itemBadge {
  position: absolute;
  bottom: 0;
  right: 15px;
}

.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}

.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}

.titleTopLeftImg {
  width: 123px;
  height: 24px;
}

.titleTopLeftImg img {
  width: 100%;
  height: 100%;
}

.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}

.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}

.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}

::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}

.titleTopRight {
  display: flex;
  align-items: center;
  padding-top: 30px;
}

.titleTopRight p {
  width: 30%;
}

.titleTopLine {
  width: 100%;
  height: 12px;
  background: #f0f2f5;
}

.titleTopCenter {
  width: 100%;
  height: 100%;
  display: flex;
}

.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
  /* min-height: 750px; */
  overflow: auto;
}

.noflex-l-s {
  display: flex;
}

.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}

.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}

.titleTopCenterCenter {
  width: 80%;
  border-radius: 6px;
  margin-left: 10px;
  background-color: #ffffff;
}

.titleTopCenterCenterTop {
  background-color: #ffffff;
  padding: 0 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.titleTopCenterCenterTopLine {
  border-bottom: 3px solid #f0f2f5;
}

.flex-l {
  display: flex;
  align-items: center;
}

.flex-l-s {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Content1Title {
  padding: 20px 40px;
  padding-left: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 0;
  text-align: left;
}

.titleTopLeftTableC {
  color: #06add7;
  cursor: pointer;
}

.ageInput {
  width: 80%;
}

.typeChecked {
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px;
  text-align: center;
  background: #00afb5;
  border-radius: 6px;
  cursor: pointer;
}

.notypeChecked {
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  padding: 0px 15px;
  border-radius: 6px;
  color: #00afb5;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.titleTopright1 {
  font-size: 16px;
  font-size: 22px;
  color: #06add7;
}

.greenColor {
  color: #06add7;
}

.titleSize16 {
  font-size: 16px;
}

.titleSize14 {
  font-size: 14px;
}

.titleSize22 {
  font-size: 22px;
}

.tagEdit {
  margin-left: 60%;
  width: 30%;
  height: 40px;
  border: 1px solid #00afb5;
  color: #00afb5;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
}

.patientimeBox {
  width: 24%;
  padding: 24px 30px;
  background: #fafafa;
  border: 1px solid #cfe5e6;
  border-radius: 8px;
  text-align: center;
  margin-right: 1%;
  cursor: pointer;
  position: relative;
}

.patientimeBox1 {
  width: 42%;
  padding: 24px 30px;
  background: #D0994A;
  /* border: 1px solid #cfe5e6; */
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  margin: 0 1%;
  cursor: pointer;
  position: relative;
  color: #FFFFFF;
  font-weight: bold;
}

.patientimeBox2 {
  width: 42%;
  padding: 24px 30px;
  background: #00AFB5;
  /* border: 1px solid #cfe5e6; */
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  margin: 0 1%;
  cursor: pointer;
  position: relative;
  color: #FFFFFF;
  font-weight: bold;
}

.patientimeBtom {
  text-align: left;
  margin-top: 15px;
}

::v-deep .el-pagination {
  padding: 10px 5px;
}

.el-page-header {
  line-height: 73px;
  /* padding-left: 20px; */
}

.seeBtn {
  font-size: 14px;
  color: #00AFB5;
  padding: 7px 12px;
  border-radius: 2px;
  background: #ffffff;
  line-height: 1;
}

.seeBtn1 {
  font-size: 14px;
  color: #D0994A;
  padding: 7px 12px;
  border-radius: 2px;
  background: #ffffff;
  line-height: 1;
}

.seeBtn2 {
  font-size: 14px;
  color: #FFFFFF;
  padding: 7px 12px;
  border-radius: 2px;
  background: #00AFB5;
  line-height: 1;
}

.titleTopLeftTable {
  width: 50%;
  padding: 20px 35px;
  font-size: 16px;
  border-bottom: 2px solid #e1e3e6;
}

.titleTopLeftTableTag {
  width: 10px;
  height: 10px;
  background: linear-gradient(90deg, #07abda, #01c4af);
  border-radius: 50%;
  margin-right: 5px;
}

.contentTitle {
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  line-height: 45px;
}

.no-data {
  height: 144px;
  text-align: center;
}

.no-data img {
  width: 100px;
  height: 100px;
}

.no-data p {
  color: #04a290;
}

.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
}
.avatar {
  width: 100px;
  height: 100px;
  margin: 0px 20px;
  display: block;
}
</style>
